import { END_POINTS } from "../../utils/config";
import { call, put, takeLatest } from "redux-saga/effects";
import axios from "../../utils/axiosConfig";
import {  BANNER_LIST_ACTION, CATEGORY_LIST_ACTION, HOMEBANNER_LIST_ACTION, HOMEBANNER_LIST_ACTION2, SUBCATEGORY_LIST_ACTION } from "../actions/types";

//listActiveCategory
function* listActiveCategory() {
    return yield axios.get(`${END_POINTS.CATEGORY}/active-categories`,{withCredentials: true})
}
export function* listActiveCategorySaga(action) {
    try {
        const response = yield call(listActiveCategory);
        // if (response?.data?.success) {
        //     yield put({ type: CATEGORY_REDUCER, payload: response?.data?.data?.categories});
        //   }
        action.callBack(response)
    } catch (error) {
        console.error('listActiveCategory failed:', error);
        action.callBack(error)
    }
}

//listActiveBanner
function* listActiveBanner() {
    return yield axios.get(`${END_POINTS.BANNER}/active-banner`,{withCredentials: true})
}
export function* listActiveBannerSaga(action) {
    try {
        const response = yield call(listActiveBanner);
        action.callBack(response)
    } catch (error) {
        console.error('listActiveCategory failed:', error);
        action.callBack(error)
    }
}
//listActiveCategory
function* listActiveSubCategry(payload) {
    return yield axios.get(`${END_POINTS.SUBCATEGORY}/active-subCategory/${payload?.id}`,{withCredentials: true})
}
export function* listActiveSubCategrySaga(action) {
    try {
        const response = yield call(listActiveSubCategry,action?.payload);
        action.callBack(response)
    } catch (error) {
        console.error('listActiveSubCategry failed:', error);
        action.callBack(error)
    }
}

//listActiveHomeBanner
function* listActiveHomeBanner() {
    return yield axios.get(`${END_POINTS.HOMEBANNER}/active-homeBanner`,{withCredentials: true})
}
export function* listActiveHomeBannerSaga(action) {
    try {
        const response = yield call(listActiveHomeBanner);
        action.callBack(response)
    } catch (error) {
        console.error('listActiveHomeBanner failed:', error);
        action.callBack(error)
    }
}

//listActiveHomeBanner2
function* listActiveHomeBanner2() {
    return yield axios.get(`${END_POINTS.HOMEBANNER}/active-homeBanner2`,{withCredentials: true})
}
export function* listActiveHomeBannerSaga2(action) {
    try {
        const response = yield call(listActiveHomeBanner2);
        action.callBack(response)
    } catch (error) {
        console.error('listActiveHomeBanner2 failed:', error);
        action.callBack(error)
    }
}



export function* categoryAndBannerSaga() {
    yield takeLatest(CATEGORY_LIST_ACTION, listActiveCategorySaga);
    yield takeLatest(BANNER_LIST_ACTION, listActiveBannerSaga);
    yield takeLatest(SUBCATEGORY_LIST_ACTION, listActiveSubCategrySaga);
    yield takeLatest(HOMEBANNER_LIST_ACTION, listActiveHomeBannerSaga);
    yield takeLatest(HOMEBANNER_LIST_ACTION2, listActiveHomeBannerSaga2);
}
export default categoryAndBannerSaga;


