import React, { useEffect, useState } from "react";
// import category from "../assets/category.json";
import Slider from "react-slick";
import "slick-carousel/slick/slick.css";
import "slick-carousel/slick/slick-theme.css";
import { listActiveCategoryAction } from "../redux/actions";
import { useDispatch, useSelector } from "react-redux";
import { useNavigate } from 'react-router-dom';

function CategorySlider() {
  const settings = {
    dots: false,
    infinite: true,
    speed: 500,
    slidesToShow: 8,
    slidesToScroll: 1,
    autoplay: true, // Enable autoplay
    autoplaySpeed: 1500,  // Set autoplay speed to 1 second
    nextArrow: <NextArrow />,
    prevArrow: <PrevArrow />,
    responsive: [
      {
        breakpoint: 1024,
        settings: {
          slidesToShow: 7,
          slidesToScroll: 1,
          infinite: true,
        },
      },
      {
        breakpoint: 768,
        settings: {
          slidesToShow: 5,
          slidesToScroll: 1,
        },
      },
      {
        breakpoint: 480,
        settings: {
          slidesToShow: 3,
          slidesToScroll: 1,
        },
      },
    ],
  };
  const dispatch = useDispatch()
  const [categories, setCategories] = useState([])
  const url = `${process.env.REACT_APP_BACKEND_URL}public/CategoryFiles/`
  const navigate = useNavigate();



  const getCategoryData = () => {
    // setScreenLoader(true);
    dispatch(
      listActiveCategoryAction((response) => {
        if (response?.data?.success) {
          const fetchedCategories = response?.data?.categories || [];
          setCategories(fetchedCategories);
        } else {
          console.error(
            "Failed to fetch categories:",
            response?.data?.message || "Unknown error"
          );
        }
        // setScreenLoader(false);
      })
    );
  };


  function redirectPage(item) {
    const categorySlug = item.categoryName.toLowerCase().replace(/ /g, "-"); 
    const categoryId = item._id || "defaultCategoryId"; 
    const path = `/${categorySlug}/${categoryId}`;
    navigate(path);
}

  // Call the fetch function on component mount
  useEffect(() => {
    getCategoryData();
  }, []);

  return (
    <div className="px-8 md:px-0 py-4 container mx-auto section pb-4">
      <Slider {...settings} className="flex">
        {categories.map((item, index) => (
          <button
            onClick={()=>redirectPage(item)}
            key={index}
            className="text-black block rounded-md slide-item"
          >
            <div className="flex justify-center items-center">
              <img
                src={`${url}${item?.categoryImage}`}
                alt={item?.categoryName}
                className="w-8 h-8 md:w-12 md:h-12 rounded-full mix-blend-color-burn object-contain"
              />
            </div>
            <p className="text-center text-[12px] md:text-[13px] font-medium mt-2">{item?.categoryName}</p>
          </button>
        ))}
      </Slider>
    </div>
  );
}

function NextArrow(props) {
  const { className, onClick } = props;
  return (
    <div className={`${className} custom-arrow next-arrow`} onClick={onClick} />
  );
}

function PrevArrow(props) {
  const { className, onClick } = props;
  return (
    <div className={`${className} custom-arrow prev-arrow`} onClick={onClick} />
  );
}

export default CategorySlider;
