import React, { useEffect, useState } from "react";
import BannerSlider from "../../components/BannerSlider";
import BestSeller from "../../components/BestSeller";
import HomeBanner from "../../components/HomeBanner";
import { getProductByDiscountAction, getTrendingProductAction, listActiveHomeBannerAction, listActiveHomeBannerAction2 } from "../../redux/actions";
import { useDispatch } from "react-redux";
import { PacmanLoader } from "react-spinners";
import Productlist from "../../components/Productlist";
import { useNavigate } from "react-router-dom";
// import {BestSellerImage1} from "/assets/homepage/bestSeller1.png"

function Home() {
  const navigate=useNavigate();
  const [products, setProducts] = useState([]);
  const [products2, setProducts2] = useState([]);
  const dispatch = useDispatch();
  const [screenLoader, setScreenLoader] = useState(false);
  const url = `${process.env.REACT_APP_BACKEND_URL}public/homeBannerFiles/`;
  const [homeBanners, setHomeBanners] = useState([])
  const [homeBanners2, setHomeBanners2] = useState([])
  
  function getActiveDiscountProduct() {
    return new Promise((resolve, reject) => {
      dispatch(
        getProductByDiscountAction((response) => {
          try {
            if (response.data.success) {
              const products = Array.isArray(response?.data?.products)
                ? response?.data?.products
                : [];
              setProducts(products);
            } else {
              setProducts([]);
            }
            resolve(); // Resolve the promise when API call is done
          } catch (error) {
            console.error("Error processing response:", error);
            reject(error); // Reject the promise in case of an error
          }
        })
      );
    });
  }
  function getTrendingProduct() {
    return new Promise((resolve, reject) => {
      dispatch(
        getTrendingProductAction((response) => {
          try {
            if (response.data.success) {
              const products = Array.isArray(response?.data?.products)
                ? response?.data?.products
                : [];
              setProducts2(products);
            } else {
              setProducts2([]);
            }
            resolve(); // Resolve the promise when API call is done
          } catch (error) {
            console.error("Error processing response:", error);
            reject(error); // Reject the promise in case of an error
          }
        })
      );
    });
  }
  const getHomeBannerData = () => {
    // setScreenLoader(true);
    dispatch(
      listActiveHomeBannerAction((response) => {
        if (response?.data?.success) {
          const homeBanner1 = response?.data?.homeBanners || [];
          // console.log(fetchedBanners,"*****************88")
          setHomeBanners(homeBanner1);
        } else {
          console.error(
            "Failed to fetch categories:",
            response?.data?.message || "Unknown error"
          );
        }
        // setScreenLoader(false);
      })
    );
  };
  const getHomeBannerData2 = () => {
    dispatch(
      listActiveHomeBannerAction2((response) => {
        if (response?.data?.success) {
          const homeBanner2 = response?.data?.homeBanners || [];
          setHomeBanners2(homeBanner2); // Update state correctly
        } else {
          console.error(
            "Failed to fetch categories:",
            response?.data?.message || "Unknown error"
          );
        }
      })
    );
  };


  useEffect(() => {
    window.scrollTo(0, 0);
    setScreenLoader(true); // Show loader before starting API calls
    Promise.all([getActiveDiscountProduct(), getHomeBannerData(), getTrendingProduct(), getHomeBannerData2()])
      .catch((error) => {
        console.error("Error during API calls:", error);
      })
      .finally(() => {
        setScreenLoader(false); // Turn off loader after calls are done
      });
  }, []);


  function getRandomItems(arr, numItems) {
    const shuffled = [...arr].sort(() => 0.5 - Math.random()); // Shuffle the array
    return shuffled.slice(0, numItems); // Return the first 'numItems' items
  }

  const discountProducts = getRandomItems(products, 10);

  function redirectPage(item) {
    const categorySlug = item.category.categoryName.toLowerCase().replace(/ /g, "-"); 
    const categoryId = item.category._id || "defaultCategoryId"; 
    const path = `/${categorySlug}/${categoryId}`;
    navigate(path);
}
  console.log(homeBanners2, "***********************homeBanners2")
  return (
    <div className="w-full">
      <BannerSlider />

      {screenLoader && (
        <div className="w-full h-48 flex justify-center items-center my-5">
          <PacmanLoader color="#065f46" size={20} />
        </div>
      )}
      <div className="bg-white p-2 py-4 md:p-6 my-10">
        <h2 className="font-bold text-sm md:text-base mb-8">
          Up to 70% Off | BestSeller from New businesses
        </h2>
        <BestSeller props={discountProducts} />
      </div>


      <div className="bg-white p-2 py-4 md:p-6 my-6">
        {/* <h2 className="font-bold text-sm md:text-base mb-3">Home Appliances</h2> */}
        <HomeBanner homeBanner={homeBanners} />
      </div>
      <div className="">
        <h2 className="font-bold text-sm md:text-base md:p-6 p-2">
          Trending Products
        </h2>
        <Productlist product={products2} />
      </div>
      <div className="flex flex-wrap justify-between gap-y-4 my-10 p-4">
        {homeBanners2.map((item, index) => (
          <div
            key={index}
            className="w-full md:w-[49%] h-auto shadow-md bg-green-600 cursor-pointer"
          >
            <img
              onClick={()=>redirectPage(item)}
              src={`${url}${item.homeBannerImage}`}
              alt={`Banner ${index + 1}`}
              className="w-full h-full object-fill"
            />
          </div>
        ))}
      </div>

    </div>
  );
}

export default Home;
