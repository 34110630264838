import React from "react";
import { useNavigate } from "react-router-dom";

function HomeBanner({ homeBanner }) {
  const url = `${process.env.REACT_APP_BACKEND_URL}public/homeBannerFiles/`;
  const navigate = useNavigate();


  function redirectPage(item) {
    const categorySlug = item.category.categoryName.toLowerCase().replace(/ /g, "-"); 
    const categoryId = item.category._id || "defaultCategoryId"; 
    const path = `/${categorySlug}/${categoryId}`;
    navigate(path);
}

  return (
    <div className="flex justify-evenly md:justify-between gap-y-3 gap-x-2 items-center flex-wrap">
      {homeBanner.map((item, index) => (
        <div 
        key={index} 
        onClick={()=>redirectPage(item)}
        className="sm:w-[48%] md:w-[32.5%] aspect-auto flex justify-center items-center flex-col hover:shadow-md hover:shadow-slate-400 transition-all rounded-xl cursor-pointer ">
          <div className="h-full w-full">
            <img src={`${url}${item.homeBannerImage}`} alt={item.name} className="w-full h-full"/>
          </div>
        </div>
      ))}
    </div>
  );
}

export default HomeBanner;
