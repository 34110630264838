import React, { useEffect, useState } from "react";
import Slider from "react-slick";
import "slick-carousel/slick/slick.css";
import "slick-carousel/slick/slick-theme.css";
import { useDispatch } from "react-redux";
import { listActiveBannerAction } from "../redux/actions";

function BannerSlider({ banner }) {
  // console.log(bannerImg)
  const settings = {
    dots: true,
    infinite: true,
    speed: 1000,
    slidesToShow: 1,
    slidesToScroll: 1,
    autoplay: true, // Enable autoplay
    arrows: false, // Disable arrow buttons
    autoplaySpeed: 3000,  // Set autoplay speed to 1 second

  };



  const dispatch = useDispatch()
  const [banners, setBanners] = useState([])
  const url = `${process.env.REACT_APP_BACKEND_URL}public/BannerFiles/`


  const getBannerData = () => {
    // setScreenLoader(true);
    dispatch(
      listActiveBannerAction((response) => {
        if (response?.data?.success) {
          const fetchedBanners = response?.data?.banners || [];
          // console.log(fetchedBanners,"*****************88")
          setBanners(fetchedBanners);
        } else {
          console.error(
            "Failed to fetch categories:",
            response?.data?.message || "Unknown error"
          );
        }
        // setScreenLoader(false);
      })
    );
  };

  // Call the fetch function on component mount
  useEffect(() => {
    getBannerData();
  }, []);

  return (
    <div className="my-3 md:my-6">
      <Slider {...settings}>
        {banners.map((item, index) => {
          return (
            <div className="w-full" key={index}>
              <img
                src={`${url}${item?.bannerImage}`}
                alt="Banner Image"
                className="w-full h-full object-contain"
              />
            </div>
          );
        })}
      </Slider>
    </div>
  );
}

export default BannerSlider;
