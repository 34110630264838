import React, { useState } from 'react';
import { useSelector, useDispatch } from 'react-redux';
import { Link } from 'react-router-dom';

const products = [
  {
    id: 1,
    name: 'Throwback Hip Bag',
    href: '#',
    color: 'Salmon',
    price: '90.00',
    quantity: 1,
    imageSrc: 'https://tailwindui.com/plus/img/ecommerce-images/shopping-cart-page-04-product-01.jpg',
    imageAlt: 'Salmon orange fabric pouch with match zipper, gray zipper pull, and adjustable hip belt.',
  },
  {
    id: 2,
    name: 'Medium Stuff Satchel',
    href: '#',
    color: 'Blue',
    price: '32.00',
    quantity: 1,
    imageSrc: 'https://tailwindui.com/plus/img/ecommerce-images/shopping-cart-page-04-product-02.jpg',
    imageAlt:
      'Front of satchel with blue canvas body, black straps and handle, drawstring top, and front zipper pouch.',
  },
  // More products...
]

export function QuickCart() {
  const [quantity, setQuantity] = useState(1);

  const increaseQuantity = () => {
    setQuantity((prevQuantity) => prevQuantity + 1);
  };

  const decreaseQuantity = () => {
    if (quantity > 1) {
      setQuantity((prevQuantity) => prevQuantity - 1);
    }
  };
  return (
    <>
      <div className="min-h-screen bg-transparent font-sans">
        <div className='max-w-4xl mx-auto bg-white shadow-xl rounded-lg p-6  md:p-12'>
          <h2 className="text-2xl font-semibold text-emerald-800 mb-4 uppercase underline">
            My shopping bag
          </h2>
          <div className='divide-y divide-gray-200'>
            {products.map((product) => (
              <li key={product.id} className="flex py-6">
                <div className="size-24 shrink-0 overflow-hidden rounded-md border border-gray-200">
                  <img alt={product.imageAlt} src={product.imageSrc} className="size-full object-cover" />
                </div>

                <div className="ml-4 flex flex-1 flex-col">
                  <div>
                    <div className="flex justify-between text-base font-medium text-gray-900">
                      <h3>
                        <a href={product.href}>{product.name}</a>
                      </h3>
                      <p className="ml-4">{product.price*product.quantity}</p>
                    </div>
                    <p className="mt-1 text-sm text-gray-800">{product.color}</p>
                  </div>
                  <div className="flex flex-1 items-end justify-between text-sm">
                    {/* <div className="text-gray-800 flex gap-2 items-center">
                        <span>Qty</span>
                        <select name="" id="" className="rounded-lg py-2">
                          <option value="1">1</option>
                          <option value="2">2</option>
                        </select>
                      </div> */}

                    <div className=" px-2 py-1 border rounded-md flex justify-around text-lg">
                      <button className="text-[24px]" onClick={decreaseQuantity}>-</button>
                      <p className="px-3">{quantity}</p>
                      <button className="text-[24px]" onClick={increaseQuantity}>+</button>
                    </div>

                    <div className="flex">
                      <button type="button" className="font-medium text-red-500 hover:text-red-700 transition-all duration-300">
                        Remove
                      </button>
                    </div>
                  </div>
                </div>
              </li>
            ))}
          </div>

          <div className="border-t border-gray-200 px-4 py-6 sm:px-6">
          <div className="flex justify-between text-base font-medium text-gray-900">
            <p>Subtotal</p>
            <p>$122.00</p>
          </div>
          <p className="mt-0.5 text-sm text-gray-800">Shipping and taxes calculated at checkout.</p>
          <div className="mt-6">
            <Link
              to={"/checkout"}
              className="flex items-center justify-center rounded-md border border-transparent bg-emerald-600 px-6 py-3 text-base font-medium text-white shadow-sm hover:bg-emerald-700 transition-all duration-300"
            >
              Checkout
            </Link>
          </div>
          <div className="mt-6 flex justify-center text-center text-sm text-gray-800">
            <p>
              or{' '}
              <Link
                to={"/"}
                type="button"
                className="font-medium text-emerald-500 hover:text-emerald-800"
              >
                Continue Shopping
                <span aria-hidden="true"> &rarr;</span>
              </Link>
            </p>
          </div>
        </div>
        </div>
      </div>
    </>
  );
}



