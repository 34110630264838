import React from 'react'
import { Outlet } from 'react-router-dom'
import Header from '../../components/Header'
import Footer from '../../components/Footer'

function LegalLayout() {
    return (
        <div className=' bg-gray-100'>
            <Header />
            <main className='p-4'>
            <Outlet />
            </main>
            <Footer />
        </div>
    )
}

export default LegalLayout