import { END_POINTS } from "../../utils/config";
import { call, put, takeLatest } from "redux-saga/effects";
import axios from "../../utils/axiosConfig";
import { GET_PRODUCT_ACTION, GET_PRODUCT_BY_DISCOUNTPRICE_ACTION, GET_TRENDINGPRODUCT_ACTION, PRODUCT_BY_SUBCATEOGRYID_LIST_ACTION, PRODUCT_LIST_ACTION } from "../actions/types";

//listActiveCategory
function* listProduct(payload) {
    return yield axios.get(`${END_POINTS.PRODUCT}/activeProduct/${payload?.id}`, { withCredentials: true })
}
export function* listProductSaga(action) {
    try {
        const response = yield call(listProduct, action?.payload);
        action.callBack(response)
    } catch (error) {
        console.error('listActiveSubCategry failed:', error);
        action.callBack(error)
    }
}


function* listProductBySubcategoryId(payload) {
    let formData = new FormData()
    Object.keys(payload).forEach(element => {
        formData.append(element, payload[element])
    });

    return yield axios.post(`${END_POINTS.PRODUCT}/subcategory-product`,formData,{ withCredentials: true })
}
export function* listProductBySubcategoryIdSaga(action) {
    try {
        const response = yield call(listProductBySubcategoryId, action?.payload);
        action.callBack(response)
    } catch (error) {
        console.error('listProductBySubcategoryId failed:', error);
        action.callBack(error)
    }
}

//get product
function* getProduct(payload) {
    return yield axios.get(`${END_POINTS.PRODUCT}/product-details/${payload.id}`, { withCredentials: true })
}
export function* getProductSaga(action) {
    try {
        const response = yield call(getProduct, action?.payload);
        action.callBack(response)
    } catch (error) {
        console.error('getProductSaga failed:', error);
        action.callBack(error)
    }
}


//get product
function* getProductByUpto70() {
    return yield axios.get(`${END_POINTS.PRODUCT}/discountPrice-product`, { withCredentials: true })
}
export function* getProductByUpto70Saga(action) {
    try {
        const response = yield call(getProductByUpto70);
        action.callBack(response)
    } catch (error) {
        console.error('getProductSaga failed:', error);
        action.callBack(error)
    }
}

//get Trending product
function* getTrendingProduct() {
    return yield axios.get(`${END_POINTS.PRODUCT}/trending-product`, { withCredentials: true })
}
export function* getTrendingProductSaga(action) {
    try {
        const response = yield call(getTrendingProduct);
        action.callBack(response)
    } catch (error) {
        console.error('getTrendingProduct failed:', error);
        action.callBack(error)
    }
}

export function* productSaga() {
    yield takeLatest(PRODUCT_LIST_ACTION, listProductSaga);
    yield takeLatest(PRODUCT_BY_SUBCATEOGRYID_LIST_ACTION, listProductBySubcategoryIdSaga);
    yield takeLatest(GET_PRODUCT_ACTION, getProductSaga);
    yield takeLatest(GET_PRODUCT_BY_DISCOUNTPRICE_ACTION, getProductByUpto70Saga);
    yield takeLatest(GET_TRENDINGPRODUCT_ACTION, getTrendingProductSaga);
}
export default productSaga;


