import React from 'react'
import { Outlet } from 'react-router-dom'
import { QuickCart } from './QuickCart'
import Header from '../../components/Header'
import Footer from '../../components/Footer'

function CartLayout() {
  return (
    <div className='bg-gray-100'>
        <Header />
        <main className='py-5'>
        <Outlet />
        </main>
        <Footer />
    </div>
  )
}

export default CartLayout