import { createBrowserRouter } from "react-router-dom";
import App from "../App";
import Home from "../pages/products-list/Home";
import Login from "../pages/auth/Login";
import ForgetPassword from "../pages/auth/ForgetPassword";
import OtpVerify from "../pages/auth/OtpVerify";
import NewPassword from "../pages/auth/NewPassword";
import Signup from "../pages/auth/Signup";
import ProductList from "../pages/products-list/ProductList"
import ProductDetails from "../pages/products-list/ProductDetails";
import Contact from "../pages/auth/Contact";
import Privacy from "../pages/legal/Privacy";
import Order_Cancellation_and_Return from "../pages/legal/Order_Cancellation_and_Return ";
import TermCondition from "../pages/legal/TermCondition";
import Layout from "../pages/auth/Layout";
import LegalLayout from "../pages/legal/LegalLayout";
import CartLayout from "../pages/cart/CartLayout";
import { QuickCart } from "../pages/cart/QuickCart";


const router = createBrowserRouter([
    
  {
    path: "/",
    element: <App />,
    children:[
            {
                path:"",
                element:<Home />,
            },
            {
                path:"/:category/:categoryID",
                element:<ProductList />

            },
            {
                path:"/:category/:subCategory/:id",
                element:<ProductDetails />
            },
    ]
  },
  {
    path: "/auth",
    element: <Layout />,
    children: [
        {
            path:"/auth",
            element:<Login />
        },
        {
            path:"login",
            element:<Login />
        },
        {
            path:"forget_password",
            element:<ForgetPassword />
        },
        {
            path:"otp_verify",
            element:<OtpVerify />
        },
        {
            path:"new_password",
            element:<NewPassword />
        },
        {
            path:"sign_up",
            element:<Signup />
        },
        {
            path:"contact",
            element:<Contact />
        },
    ],
},
{
    path: "/legal",
    element: <LegalLayout />,
    children: [
        {
            path:"privacy",
            element:<Privacy />
        },
        {
            path:"order-cancellation-and-return",
            element:<Order_Cancellation_and_Return />
        },
        {
            path:"term-codition",
            element:<TermCondition />
        }
    ],
},
{
    path: "/cart",
    element: <CartLayout />,
    children: [
        {
            path:"",
            element:<QuickCart />
        },
    ],
},
]);


export default router;