import React from "react";
import { useNavigate } from "react-router-dom";

function BestSeller({ props }) {
  const url = `${process.env.REACT_APP_BACKEND_URL}public/productFiles/`;
  const navigate=useNavigate();


  function redirectPage(item) {
    console.log(item);
    const categorySlug = item?.category?.categoryName.toLowerCase().replace(/ /g, "-"); 
    const subCategorySlug = item?.subCategory?.subCategoryName.toLowerCase().replace(/ /g, "-"); 
    const productId = item._id || "defaultCategoryId"; 
    const path = `/${categorySlug}/${subCategorySlug}/${productId}`;
    // console.log(path,"888888888888888888888888888")
    navigate(path);
}


  return (
    <div className="flex justify-evenly md:justify-between gap-y-6 gap-2 flex-wrap">
      {props.map((item, index) => {
        return (
          <div
            key={index}
            className="h-[250px] aspect-auto w-[47%] md:w-56 flex justify-center items-center cursor-pointer transition-all rounded-xl overflow-hidden"
          >
            <div
              onClick={() => redirectPage(item)}
              className="flex justify-center items-center rounded-xl overflow-hidden"
            >
              <img
                src={`${url}${item.productImage1}`}
                alt={`Best Seller ${index}`}
                className="w-[90%] h-full object-contain hover:scale-110 cursor-pointer transition-all rounded-2xl"
              />
            </div>
          </div>
        );
      })}
    </div>
  );
}
export default BestSeller;
