export const SIGNUP="SIGNUP"
export const LOGIN= "LOGIN"
export const LOGOUT = "LOGOUT"



//category
export const CATEGORY_LIST_ACTION="CATEGORY_LIST_ACTION"
// export const CATEGORY_REDUCER="CATEGORY_REDUCER"

//banner
export const BANNER_LIST_ACTION="BANNER_LIST_ACTION"
export const HOMEBANNER_LIST_ACTION="HOMEBANNER_LIST_ACTION"
export const HOMEBANNER_LIST_ACTION2="HOMEBANNER_LIST_ACTION2"

//subcategory
export const SUBCATEGORY_LIST_ACTION="SUBCATEGORY_LIST_ACTION"

//product
export const PRODUCT_LIST_ACTION="PRODUCT_LIST_ACTION"

//product by subcateogryId
export const PRODUCT_BY_SUBCATEOGRYID_LIST_ACTION="PRODUCT_BY_SUBCATEOGRYID_LIST_ACTION"

// single product
export const GET_PRODUCT_ACTION="GET_PRODUCT_ACTION"

//discountProduct
export const GET_PRODUCT_BY_DISCOUNTPRICE_ACTION="GET_PRODUCT_BY_DISCOUNTPRICE_ACTION"

// trending product
export const GET_TRENDINGPRODUCT_ACTION="GET_TRENDINGPRODUCT_ACTION"


