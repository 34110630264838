import React, { useEffect, useState } from "react";
import { useParams } from "react-router-dom";
import mobile from "../../assets/mobile.json";
import { FaStar } from "react-icons/fa6";
import { FaRegStarHalfStroke } from "react-icons/fa6";
import { MdLocalOffer } from "react-icons/md";
import { getProductAction, listActiveProductBySubcatoegyIdAction } from "../../redux/actions";
import { useDispatch } from "react-redux";
import { PacmanLoader } from "react-spinners";
import Productlist2 from "../../components/Productlist2";
import ProductImageMagnify from "../../components/ProductImageMagnify";

function ProductDetails() {
  const { id } = useParams();
  const [screenLoader, setScreenLoader] = useState(false);
  const dispatch = useDispatch();
  const [product, setProduct] = useState([])
  const url = `${process.env.REACT_APP_BACKEND_URL}public/productFiles/`;
  const [products, setProducts] = useState([]);
  const [changeImage, setChangeProduct] = useState(null);
  const [quantity, setQuantity] = useState(1);

  const data = mobile.find((item, index) => {
    return item.id === 2;
  });
  // console.log(data);

  const getProductData = () => {
    const payload = { id: id }
    setScreenLoader(true)
    dispatch(
      getProductAction(payload, (response) => {
        if (response?.data?.success) {
          setChangeProduct(response?.data?.product?.productImage1)
          setProduct(response?.data?.product || []);
        } else {
          console.error(
            "Failed to fetch categories:",
            response?.data?.message || "Unknown error"
          );
        }
        setScreenLoader(false)
      })
    );
  };

  useEffect(() => {
    getProductData();
    window.scrollTo(0, 0);
  }, []); // Initial fetch on component mount

  useEffect(() => {
    if (product?.category?._id && product?.subCategory?._id) {
      getProductBySubcategoryId();
    }
  }, [product]); // Call when `product` is updated



  const getProductBySubcategoryId = () => {
    const payload = {
      category: product?.category?._id,
      subCategory: product?.subCategory?._id,
    };
    setScreenLoader(true);
    dispatch(
      listActiveProductBySubcatoegyIdAction(payload, (response) => {
        if (response?.data?.success) {
          const products = Array.isArray(response?.data?.products)
            ? response?.data?.products.filter((item) => item._id !== product._id) // Exclude current product
            : [];
          setProducts(products);
        } else {
          setProducts([]);
        }
        setScreenLoader(false);
      })
    );
  };



  const productAssets = [
    product.productImage1,
    product.productImage2,
    product.productImage3,
    product.productImage4,
    ...(product.addMoreImage || []),
    product.productVideo,
  ].filter(Boolean);


  console.log("productAssets", productAssets);
  function changeProductImage(image) {
    console.log("Image hovered: ", image);
    setChangeProduct(image);
  }

  // console.log(productAssets, "*productAssets*********************")


  const increaseQuantity = () => {
    setQuantity((prevQuantity) => prevQuantity + 1);
  };

  const decreaseQuantity = () => {
    if (quantity > 1) {
      setQuantity((prevQuantity) => prevQuantity - 1);
    }
  };
  return (
    <div className="p-2 md:py-4 md:px-6 bg-white">
      {screenLoader && (
        <div className="w-full h-48 flex justify-center items-center">
          <PacmanLoader color="#065f46" size={20} />
        </div>
      )}
      <div className="w-full flex justify-between gap-10 flex-wrap bg-trnasparent md:flex-nowrap py-3 md:py-5">
        <div className="w-full lg:w-1/2 bg-transparent flex flex-wrap md:flex-nowrap justify-between gap-2">
          <div className="overflow-x-auto md:overflow-y-auto w-full no-scrollbar md:h-[410px] md:w-20 bg-transparent order-2 md:order-1 flex justify-start gap-3 items-start flex-row md:flex-col">
            {productAssets.length > 0 &&
              productAssets.map((item, index) => {
                const imageExtensions = ['.jpg', '.jpeg', '.png', '.webp', '.gif'];
                const videoExtensions = ['.mp4', '.mov', '.avi', '.mkv', '.webm'];

                const isImage = imageExtensions.some(ext => item.toLowerCase().endsWith(ext));
                const isVideo = videoExtensions.some(ext => item.toLowerCase().endsWith(ext));

                return (
                  <div
                    key={index}
                    className="w-44 md:w-[80%] h-20 p-1 border-2 hover:border-blue-600 cursor-pointer"
                    onClick={() => changeProductImage(item)}
                  >
                    {isImage ? (
                      <>
                        <img src={`${url}${item}`} alt={item} className="w-full h-full object-contain" />
                      </>
                    ) : isVideo ? (
                      <video src={`${url}${item}`} className="w-full h-full object-contain" />
                    ) : (
                      <p>Unsupported file type</p>
                    )}
                  </div>
                );
              })}          </div>

          <div className="w-2/3 md:h-[410px] md:flex-grow order-1 md:order-2 flex justify-center md:justify-center items-center border p-4 mx-auto mb-6 md:mb-0">
            {changeImage ? (
              changeImage.toLowerCase().endsWith('.mp4') ||
                changeImage.toLowerCase().endsWith('.mov') ||
                changeImage.toLowerCase().endsWith('.avi') ||
                changeImage.toLowerCase().endsWith('.mkv') ||
                changeImage.toLowerCase().endsWith('.webm') ? (
                <video
                  autoPlay
                  muted
                  loop // Optional: to make the vi
                  src={`${url}${changeImage}`}
                  className="h-[90%] w-full object-contain"
                />
              ) : (
                <>
                  <img
                    src={`${url}${changeImage}`}
                    alt="media"
                    className="h-[90%] w-full object-contain"
                  />
                  {/* <ProductImageMagnify url={url} changeImage={changeImage} /> */}
                </>
              )
            ) : (
              <p>No media available</p> // Fallback for empty `changeImage`
            )}
          </div>
        </div>

        <div className="w-full md:flex-grow">
          <div className="overflow-y-scroll scroll-smooth flex flex-col gap-y-2 h-[350px] no-scrollbar">
            <p className="text-base md:text-lg w-full font-semibold">{product?.productName}</p>
            {
              product.brand && <p className="font-bold text-base md:text-lg">
                Brand: {product?.brand}
              </p>
            }
            <p className="flex justify-start items-center ">
              {" "}
              <FaStar className="text-yellow-500" />
              <FaStar className="text-yellow-500" />
              <FaStar className="text-yellow-500" />
              <FaStar className="text-yellow-500" />
              <FaRegStarHalfStroke className="text-yellow-500" />
              &nbsp; <span className="text-blue-500">({data.user} users)</span>
            </p>
            <p className="text-orange-500 font-bold text-2xl">
              ₹{product.totalPrice}&nbsp;&nbsp;<del className="text-gray-600 text-[16px]">₹{product.price}</del>&nbsp;&nbsp;<span className="text-[18px] text-green-600">{product.discountPrice}% off</span>
            </p>

            <div className="pl-2">
              <p className="mb-2">Available offers</p>
              <div className="flex flex-row items-center flex-wrap mb-2">
                <MdLocalOffer className="text-lg text-blue-500 " />{" "}
                <b className="text-[14px] md:text-[15px] mx-2">Bank Offer 5%</b>
                <p className="text-[13px] md:text-[14px]">Cashback on Flipkart Axis Bank Card</p>
                <p className="text-[13px] md:text-[14px] ml-1 hover:underline duration-300 text-blue-500">
                  T&C
                </p>
              </div>
              <div className="flex flex-row items-center flex-wrap mb-2">
                <MdLocalOffer className="text-lg text-blue-500 " />{" "}
                <b className="text-[14px] md:text-[15px] mx-2">Partner Offer</b>
                <p className="text-[13px] md:text-[14px]">
                  Make a purchase and enjoy a surprise cashback/ coupon that you
                  can redeem later!
                </p>
                <p className="text-[13px] md:text-[14px] ml-1 hover:underline duration-300 text-blue-500">
                  T&C
                </p>
              </div>
              <div className="flex flex-row items-center flex-wrap mb-2">
                <MdLocalOffer className="text-lg text-blue-500 " />{" "}
                <b className="text-[14px] md:text-[15px] mx-2">Special Price</b>
                <p className="text-[13px] md:text-[14px]">
                  Get extra ₹3200 off (price inclusive of cashback/coupon)
                </p>
                <p className="text-[13px] md:text-[14px] ml-1 hover:underline duration-300 text-blue-500">
                  Know more
                </p>
              </div>
              <p className="flex flex-row items-center gap-1 flex-wrap mb-2">
                <MdLocalOffer className="text-lg text-blue-500 " />{" "}
                <b className="text-[14px] md:text-[15px] mx-2">No cost EMI ₹2,933/month</b>
                <p className="text-[13px] md:text-[14px]">Standard EMI also available</p>
                <p className="text-[13px] md:text-[14px] ml-1 hover:underline duration-300 text-blue-500">
                  View Plans
                </p>
              </p>
            </div>

            <div className=" flex flex-col">
              <div className="flex gap-4 md:gap-6 items-center">
                {
                  product.stock?.length ? <p className="mt-2 text-green-500 font-bold">IN STOCK</p> : <p className="mt-2 text-red-500 font-medium">OUT OF STOCK</p>
                }
                <div className=" px-2 py-1 border rounded-md flex justify-around text-lg">
                  <button className="text-[24px]" onClick={decreaseQuantity}>-</button>
                  <p className="px-3">{quantity}</p>
                  <button className="text-[24px]" onClick={increaseQuantity}>+</button>
                </div>
              </div>

              <div className="text-sm">
                {
                  product.productColor && <><div className="pl-4 flex justify-start items-center gap-3">
                    <span className="text-gray-600 text-base">Color: </span>{" "}
                    <p style={{ color: `${product.productColor}` }}>{product.productColor}</p>
                  </div></>
                }
              </div>
              <div className="text-sm">
                {
                  product.productSize && <><div className="pl-4 flex justify-start items-center gap-3">
                    <span className="text-gray-600 text-base">Size: </span>{" "}
                    <p>{product.productSize}</p>
                  </div></>
                }
              </div>
            </div>
            <div className="p-2 md:p-0">
              <div className="font-medium">Description : </div>
              <div
                className="text-[13px]  md:text-[14px] product-description list-disc text-justify"
                dangerouslySetInnerHTML={{ __html: product?.description }}>

              </div>
            </div>
          </div>
          <div className="flex justify-center md:justify-start items-center gap-10 my-5">
            <button className="py-2 border rounded-md  w-1/2 md:w-1/4 bg-slate-700 text-white hover:bg-slate-800 font-bold duration-300">
              Buy Now
            </button>
            <button className="py-2 border rounded-md  w-1/2 md:w-1/4 bg-orange-500 text-white hover:bg-orange-600 font-bold duration-300">
              Add to Cart
            </button>

          </div>
        </div>
      </div>

      <div className="my-6">
        <h1 className="text-lg md:text-2xl font-bold mb-1">Similar Products</h1>
        <Productlist2 product={products} />
      </div>
    </div>
  );
}

export default ProductDetails;
