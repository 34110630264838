import React from "react";
import { Link, useNavigate } from "react-router-dom";

function Productlist({ product }) {
  const url = `${process.env.REACT_APP_BACKEND_URL}public/productFiles/`;
  const navigate=useNavigate();
  // console.log(product[0],"*****************************88888")

  function redirectPage(item) {
    console.log(item);
    const categorySlug = item?.category?.categoryName.toLowerCase().replace(/ /g, "-"); 
    const subCategorySlug = item?.subCategory?.subCategoryName.toLowerCase().replace(/ /g, "-"); 
    const productId = item._id || "defaultCategoryId"; 
    const path = `/${categorySlug}/${subCategorySlug}/${productId}`;
    // console.log(path,"888888888888888888888888888")
    navigate(path);
}

  return (
    <div className="w-full flex justify-evenly md:justify-stretch items-center gap-4 md:gap-[22px] flex-wrap my-4">
      {product.length > 0 ? (
        product.map((item, index) => (
          <Link
            onClick={()=>redirectPage(item)}
            key={index}
            className="bg-transparent w-44 md:w-[200px] flex flex-col justify-between items-center gap-y-3 p-4 rounded-md min-h-[240px] md:min-h-[330px] hover:shadow-xl transition-all duration-150"
          >
            {/* Image Container */}
            <div className="flex justify-center items-center w-full flex-grow">
              <img
                src={`${url}${item.productImage1}`}
                alt={item.productName} // Use product name for alt text
                className="duration-200 hover:scale-110 w-24 md:w-full h-[200px] object-contain"
              />
            </div>

            {/* Text and Price Container */}
            <div className="flex flex-col justify-start w-full">
              <p className="w-full font-medium text-[12px]">
                {item.productName.length > 50
                  ? `${item.productName.slice(0, 50)}...`
                  : item.productName} {/* Updated slicing */}
              </p>
              <p>
                {item.discountPrice ? (
                  <b className="text-green-500 text-[12px]">
                    {item.discountPrice}% Off{" "}
                    <del className="font-semibold text-gray-500 text-[10px]">
                      ₹{item.price}
                    </del>{" "}
                  </b>
                ) : null}
                <span className="text-black text-[12px]">₹{item.totalPrice}</span>
              </p>
            </div>
          </Link>

        ))
      ) : (
        <div className="text-red-500">Product Not Available</div>
      )}
    </div>
  );
}

export default Productlist;
