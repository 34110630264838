import React, { useState } from "react";
import { IoSearchSharp } from "react-icons/io5";
import { FaUser } from "react-icons/fa";
import { FaCartShopping } from "react-icons/fa6";
import { MdOutlineStorefront } from "react-icons/md";
import { BsThreeDotsVertical } from "react-icons/bs";
import { Link } from "react-router-dom";
import { RiContactsBook2Fill } from "react-icons/ri";
import { MdRoundaboutRight } from "react-icons/md";
import logoImg from "../assets/beyondLogo.png"

// import CategorySlider from "./CategorySlider";

function Header() {
  const [show, setShow] = useState(false);

  const productCart = ["product1", "product2","product1", "product2","product1", "product2"]

  return (
    <>
      <header>
        <div className="h-[70px] border-b-2 bg-white mb-3 shadow-md">
          <div className="container mx-auto px-4 h-full flex justify-between items-center gap-5">
            <Link to="/">
              <img src={logoImg} alt="Logo" className="w-12 h-12" />
              {/* <span className="text-emerald-800 font-bold">BeYond</span> */}
            </Link>

            <div className="hidden md:flex w-full justify-between md:max-w-sm lg:max-w-[500px] items-center bg-slate-200 rounded-lg focus-within:shadow h-10 pl-2">
              <div className="h-full w-8 flex justify-center items-center text-gray-600">
                <IoSearchSharp className="text-2xl" />
              </div>
              <input
                type="text"
                placeholder="Search for Product, Brand and More"
                className="text-gray-600 w-full outline-none h-full border rounded-lg bg-transparent pl-2"
              />
            </div>

            <div className="flex justify-center items-center gap-4 md:gap-8">
              <Link
                to="/auth/login" // Add your desired route here
                className="flex gap-2 items-center py-1 px-2 hover:bg-slate-100 border border-transparent hover:border hover:border-slate-100 rounded-lg cursor-pointer duration-300"
              >
                <div className="p-2 bg-emerald-800 rounded-full">
                  <FaUser className="text-md text-white" />
                </div>
                <p className="hidden md:block">Login</p>
              </Link>

              <Link
                to="/cart" // Add your desired route here
                className="flex gap-2 items-center cursor-pointer"
              >
                <div className="p-2 bg-emerald-800 rounded-full relative">
                  <FaCartShopping className="text-md text-white" />
                  <p className="absolute -top-2 -right-2 text-[14px] text-red-800 font-bold">
                    {/* 0 */}
                  </p>
                <p className="text-white text-[10px] bg-red-600 px-[6px] py-[2px] rounded-full absolute -top-3 -right-3 border-2 border-white">{productCart.length}</p>
                </div>
                <p className="hidden md:block">Cart</p>
              </Link>

              <div
                onClick={() => window.open("https://seller-ecommerce.markletechandmedia.com", "_blank")}
                className="gap-2 items-center cursor-pointer hidden lg:flex"
              >
                <div className="p-2 bg-emerald-800 rounded-full relative">
                  <MdOutlineStorefront className="text-md text-white" />
                </div>
                <p>Become a Seller</p>
              </div>

              <div className="relative">
                <div
                  onMouseEnter={() => setShow(true)}
                  onMouseLeave={() => setShow(false)}
                  className=" cursor-pointer hover:bg-slate-100 border border-transparent hover:border hover:border-slate-100 rounded-lg p-3 "
                >
                  <BsThreeDotsVertical />
                </div>
                {show ? (
                  <div
                    onMouseEnter={() => setShow(true)}
                    onMouseLeave={() => setShow(false)}
                    className="w-40 border bg-white absolute top-8 right-0 z-50 px-2 py-3 rounded-b-lg flex flex-col gap-4 duration-300"
                  >
                    <Link
                      to="/auth/contact"
                      className="text-sm flex flex-row gap-x-2 items-center"
                    >
                      <RiContactsBook2Fill /> Contact Us
                    </Link>
                    <div
                      onClick={() => window.open("https://seller-ecommerce.markletechandmedia.com", "_blank")}
                      className="text-sm flex flex-row gap-x-2 items-center lg:hidden"
                    >
                      <MdOutlineStorefront /> Become a Seller
                    </div>
                  </div>
                ) : (
                  ""
                )}
              </div>
            </div>
          </div>
        </div>

        <div className="container mx-auto px-2 mb-3">
          <div className=" flex md:hidden w-full justify-between max-w-[600px] items-center bg-white rounded-lg focus-within:shadow p-2">
            <div className="h-full w-8 flex justify-center items-center text-gray-600">
              <IoSearchSharp className="text-2xl" />
            </div>
            <input
              type="text"
              placeholder="Search for Product, Brand and More"
              className="text-gray-600 w-full outline-none h-full text-sm rounded-lg bg-transparent pl-2"
            />
          </div>
        </div>
      </header>
    </>
  );
}

export default Header;
