
import { all } from 'redux-saga/effects';
import authSaga from './auth.saga';
import  { categoryAndBannerSaga } from './categoryAndBanner.saga';
import productSaga from './product.saga';

export default function* rootSaga() {
  yield all([
    authSaga(),
    categoryAndBannerSaga(),
    productSaga()
  ]);
}
