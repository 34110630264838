
export const BASE_URL = process.env.REACT_APP_BACKEND_API_URL
// console.log(process.env.REACT_APP_BACKEND_API_URL,"**********************")

export const END_POINTS = {
    CATEGORY:"category",
    BANNER:"banner",
    SUBCATEGORY:"subCategory",
    PRODUCT:'product',
    HOMEBANNER:'homeBanner'

}