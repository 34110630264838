import React, { useEffect } from 'react';

function Privacy() {
    useEffect(() => {
        // Scroll to the top of the page when the component mounts
        window.scrollTo(0, 0);
    }, []);

    return (
        <div className="min-h-screen bg-transparent font-sans">
            <div className="max-w-4xl mx-auto bg-white shadow-xl rounded-lg p-6  md:p-12">
                <h1 className="text-3xl font-bold text-emerald-800 mb-4">Privacy Policy</h1>
                <p className="text-sm text-gray-600 mb-6">
                    Last Updated: <span className="font-semibold">16th November 2024</span>
                </p>

                <div className="space-y-6">
                    <section>
                        <h2 className="text-xl font-semibold text-gray-700 mb-2">Introduction</h2>
                        <p className="text-gray-700 leading-relaxed">
                            We value the trust you place in us and recognize the importance of secure transactions and information privacy. 
                            This Privacy Policy describes how Shopsy collects, uses, and processes your personal information through our 
                            mobile application (referred to as the "Platform").
                        </p>
                    </section>

                    <section>
                        <h2 className="text-xl font-semibold text-gray-700 mb-2">Collection of Your Information</h2>
                        <p className="text-gray-700 leading-relaxed">
                            When you use our Platform, we collect and store your information and the information of the end-customers, 
                            as provided by you, to make purchases on their behalf upon authorization and confirmation.
                        </p>
                    </section>

                    <section>
                        <h2 className="text-xl font-semibold text-gray-700 mb-2">Use of Demographic/Profile Data</h2>
                        <p className="text-gray-700 leading-relaxed">
                            We use your personal information to provide the products and services you request. We also use it to improve customer 
                            experience, resolve disputes, troubleshoot problems, and customize your experience.
                        </p>
                    </section>

                    <section>
                        <h2 className="text-xl font-semibold text-gray-700 mb-2">Cookies</h2>
                        <p className="text-gray-700 leading-relaxed">
                            We use "cookies" to help analyze web page flow, measure promotional effectiveness, and promote trust and safety. Cookies do not 
                            contain personal information, and you can opt-out or delete them if needed.
                        </p>
                    </section>

                    <section>
                        <h2 className="text-xl font-semibold text-gray-700 mb-2">Sharing of Personal Information</h2>
                        <p className="text-gray-700 leading-relaxed">
                            We may share personal information with third parties for purposes such as order fulfillment, customer support, and legal 
                            compliance. Your explicit consent will be required for certain sharing activities.
                        </p>
                    </section>

                    <section>
                        <h2 className="text-xl font-semibold text-gray-700 mb-2">Your Rights</h2>
                        <p className="text-gray-700 leading-relaxed">
                            You have the right to access, correct, or update your personal information. You can also withdraw your consent for data processing 
                            by contacting us.
                        </p>
                    </section>
                </div>

                <div className="mt-8 border-t pt-4">
                    <p className="text-sm text-gray-600 text-center">
                        For any queries or concerns, please contact us at <a href='https://markletechandmedia.com/' className='font-bold' target="_blank" rel="noopener noreferrer">markletechandmedia.com</a>.
                    </p>
                </div>
            </div>
        </div>
    );
}

export default Privacy;
