import React from "react";
import { Link, useNavigate } from "react-router-dom";



function Productlist2({ product }) {
  const url = `${process.env.REACT_APP_BACKEND_URL}public/productFiles/`;
  const navigate = useNavigate();

  function redirectPage(item) {
    console.log(item);
    const categorySlug = item?.category?.categoryName.toLowerCase().replace(/ /g, "-");
    const subCategorySlug = item?.subCategory?.subCategoryName.toLowerCase().replace(/ /g, "-");
    const productId = item._id || "defaultCategoryId";
    const path = `/${categorySlug}/${subCategorySlug}/${productId}`;
    navigate(path);
    window.location.reload();
  }
  return (
    <div className="w-full flex gap-y-2 md:gap-6 justify-between md:justify-normal flex-wrap p-4">
      {product.map((item, index) => (
        <button
          onClick={() => redirectPage(item)}
          key={index}
          className="w-[49%] sm:w-48 md:w-[220px] flex flex-col bg-transparent rounded-sm hover:shadow-2xl p-2"
        >
          <div className="w-full flex justify-center items-center py-5">
            <img
              src={`${url}${item?.productImage1}`}
              alt=""
              className="object-contain w-[200px] h-[200px] md:w-[400px] md:h-[250px]"
            />
          </div>
          <div className="mt-3 p-2">
            <p className="w-full font-semibold text-[12px] md:text-md text-left">
              {`${item.productName.slice(0, 50)}...`}
            </p>
            <p className="text-orange-500 font-bold text-[13px] md:text-md mt-1 text-left">
              ₹{item.totalPrice}&nbsp;&nbsp;
              <del className="text-gray-600 text-[10px] md:text-sm">₹{item.price}</del>&nbsp;&nbsp;
              <span className="text-green-600">{item.discountPrice}% Off</span>
            </p>
            {/* <h2 className="text-sm">
              <sup>* including band & coupon offer</sup>
            </h2> */}
          </div>
        </button>

      ))}
    </div>
  );
}

export default Productlist2;
