import React, { useEffect, useState } from "react";
import Productlist from "../../components/Productlist";
import { useDispatch } from "react-redux";
import { listActiveProductAction, listActiveProductBySubcatoegyIdAction, listActiveSubcategoryAction } from "../../redux/actions";
import { useParams } from "react-router-dom";
import { PacmanLoader } from "react-spinners";
import Slider from "react-slick";

const settings = {
  dots: false,
  infinite: false,
  speed: 500,
  slidesToShow: 6,
  slidesToScroll: 1,
  // autoplay: true, // Enable autoplay
  // autoplaySpeed: 1500,  // Set autoplay speed to 1 second
  nextArrow: <NextArrow />,
  prevArrow: <PrevArrow />,
  responsive: [
    {
      breakpoint: 1024,
      settings: {
        slidesToShow: 5,
        slidesToScroll: 1,
        infinite: true,
      },
    },
    {
      breakpoint: 768,
      settings: {
        slidesToShow: 4,
        slidesToScroll: 1,
      },
    },
    {
      breakpoint: 480,
      settings: {
        slidesToShow: 2,
        slidesToScroll: 1,
      },
    },
  ],
};


function Mobile() {
  const { categoryID, category } = useParams();

  const [subCategories, setSubCategories] = useState([]);
  const dispatch = useDispatch();
  const [screenLoader, setScreenLoader] = useState(false);
  const [screenLoader2, setScreen2Loader] = useState(false);
  const [products, setProducts] = useState([]);

  // Function to get active subcategories
  function getActiveSubCategory() {
    return new Promise((resolve) => {
      const payload = { id: categoryID };
      dispatch(
        listActiveSubcategoryAction(payload, (response) => {
          if (response.data.success) {
            const subCategories = Array.isArray(response?.data?.subCategories)
              ? response?.data?.subCategories
              : [];
            setSubCategories(subCategories);
          } else {
            setSubCategories([]);
          }
          resolve(); // Resolve the promise when API call is done
        })
      );
    });
  }

  // Function to get active products
  function getActiveProduct(id = categoryID) {
    return new Promise((resolve) => {
      const payload = { id: id };
      dispatch(
        listActiveProductAction(payload, (response) => {
          if (response.data.success) {
            const products = Array.isArray(response?.data?.products)
              ? response?.data?.products
              : [];
            setProducts(products);
          } else {
            setProducts([]);
          }
          resolve(); // Resolve the promise when API call is done
        })
      );
    });
  }

  // Function to get products by subcategory ID
  function getProductBySubcategoryId(id) {
    const payload = { category: categoryID, subCategory: id };
    console.log(payload);

    // Show loader before API call starts
    setScreen2Loader(true);

    dispatch(
      listActiveProductBySubcatoegyIdAction(payload, (response) => {
        if (response?.data?.success) {
          const products = Array.isArray(response?.data?.products)
            ? response?.data?.products
            : [];
          setProducts(products);
        } else {
          setProducts([]);
        }

        // Hide loader after the API call completes
        setScreen2Loader(false);
      })
    );
  }

  // useEffect to handle fetching of subcategories and products
  useEffect(() => {
    window.scrollTo(0, 0);

    setScreenLoader(true); // Show loader before starting API calls
    Promise.all([getActiveSubCategory(), getActiveProduct()]).finally(() => {
      setScreenLoader(false); // Turn off loader after both calls are done
    });
  }, [categoryID]);

  return (
    <div className="w-full">
      {screenLoader && (
        <div className="w-full h-48 flex justify-center items-center my-5">
          <PacmanLoader color="#065f46" size={20} />
        </div>
      )}

      {/* <div className="w-full p-4 flex justify-stretch xl:justify-stretch items-center flex-wrap gap-y-3 gap-x-8 font-bold bg-slate-800 text-white mt-5"> */}
      {/* {Array.isArray(subCategories) && subCategories.length > 0 ? (
          subCategories.map((item, index) => (
            <button
              key={index}
              onClick={() => getProductBySubcategoryId(item?._id)}
              className="rounded-md text-sm transition duration-300 ease-in-out text-gray-100 hover:text-gray-300"
            >
              {item.subCategoryName}
            </button>
          ))
        ) : (
          <div className="text-gray-400">
            No subcategories available for <span className="text-gray-300">{category}</span>
          </div>
        )} */}

      <div className="w-full mx-auto section py-4 px-7 animate-slideBackground">

        <Slider {...settings} className="flex" style={{
        }}>
          {
            Array.isArray(subCategories) && subCategories.length > 0 ? (
              subCategories.map((item, index) => (
                <button
                  key={index}
                  onClick={() => getProductBySubcategoryId(item?._id)}
                  className="rounded-md text-[14px] font-medium transition duration-300 ease-in-out text-gray-200 hover:text-gray-400"
                >
                  {item.subCategoryName}
                </button>
              ))
            ) : (
              <p className="text-center text-gray-500">No categories available</p>
            )
          }

        </Slider>


      </div>

      {screenLoader2 && (
        <div className="w-full h-48 flex justify-center items-center">
          <PacmanLoader color="#065f46" size={20} />
        </div>
      )}
      <div className="p-2 md:p-4 flex justify-center">
        <Productlist product={products} />
      </div>
    </div>
  );
}

export default Mobile;


function NextArrow(props) {
  const { className, onClick } = props;
  return (
    <div className={`${className} custom-arrow next-arrow2`} onClick={onClick} />
  );
}

function PrevArrow(props) {
  const { className, onClick } = props;
  return (
    <div className={`${className} custom-arrow prev-arrow2`} onClick={onClick} />
  );
}
