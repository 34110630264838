import React, { useEffect } from 'react'

function Order_Cancellation_and_Return () {
    
    useEffect(() => {
        // Scroll to the top of the page when the component mounts
        window.scrollTo(0, 0);
    }, []);
  return (
    <div className="min-h-screen bg-transparent font-sans">
    {/* Main Content */}
    <main className="container mx-auto px-6 py-8">
      <section className="max-w-4xl mx-auto bg-white shadow-xl rounded-lg p-6  md:p-12">
        <h2 className="text-2xl font-semibold text-emerald-800 mb-4">
          Order Cancellation & Return Policy
        </h2>

        {/* Cancellation Policy */}
        <div className="mb-8">
          <h3 className="text-lg font-semibold text-gray-800 mb-2">
            Cancellation Policy
          </h3>
          <p className="text-gray-600 mb-4">
            You can cancel your order before it is shipped. Once the order is
            shipped, cancellation is not possible. For prepaid orders,
            refunds will be processed within 5-7 business days.
          </p>
          <ul className="list-disc list-inside text-gray-600">
            <li>If the order is canceled before shipping, no cancellation charges will apply.</li>
            <li>For customized products, cancellation is not allowed.</li>
            <li>Refunds for canceled orders will reflect in your account within 5-7 business days.</li>
          </ul>
        </div>

        {/* Return Policy */}
        <div>
          <h3 className="text-lg font-semibold text-gray-800 mb-2">Return Policy</h3>
          <p className="text-gray-600 mb-4">
            We offer easy returns for products that are damaged, defective, or
            not as described. The return policy varies based on the product category.
          </p>
          <ul className="list-disc list-inside text-gray-600">
            <li>Returns are accepted within 7 days of delivery for eligible products.</li>
            <li>Ensure the product is in its original condition with tags and packaging.</li>
            <li>Refunds will be processed within 5-7 business days after the return is approved.</li>
            <li>Certain products such as perishables, hygiene products, and customized items are not eligible for return.</li>
          </ul>
        </div>
      </section>
    </main>
  </div>  )
}

export default Order_Cancellation_and_Return 