import * as TYPES from './types'

export const sigupAction =(payload,callBack)=>(
    {
        type: TYPES["SIGNUP"],payload,callBack
    }
)
export const loginAction = (payload, callBack) => (
    {
    type: TYPES['LOGIN'], payload, callBack
})


// category and banner
export const listActiveCategoryAction = ( callBack) => ({
    type: TYPES['CATEGORY_LIST_ACTION'],callBack
})

export const listActiveBannerAction = ( callBack) => ({
    type: TYPES['BANNER_LIST_ACTION'],callBack
})

export const listActiveSubcategoryAction = (payload, callBack) => ({
    type: TYPES['SUBCATEGORY_LIST_ACTION'],payload,callBack
})

//PRODUCT
export const listActiveProductAction = (payload, callBack) => ({
    type: TYPES['PRODUCT_LIST_ACTION'],payload,callBack
})

export const listActiveProductBySubcatoegyIdAction = (payload, callBack) => ({
    type: TYPES['PRODUCT_BY_SUBCATEOGRYID_LIST_ACTION'],payload,callBack
})
// single product
export const getProductAction = (payload, callBack) => ({
    type: TYPES['GET_PRODUCT_ACTION'],payload,callBack
})

//Discount product
export const getProductByDiscountAction = (callBack) => ({
    type: TYPES['GET_PRODUCT_BY_DISCOUNTPRICE_ACTION'],callBack
})

//trending product
export const getTrendingProductAction = (callBack) => ({
    type: TYPES['GET_TRENDINGPRODUCT_ACTION'],callBack
})

// home Banner
export const listActiveHomeBannerAction = ( callBack) => ({
    type: TYPES['HOMEBANNER_LIST_ACTION'],callBack
})
// home Banner
export const listActiveHomeBannerAction2 = ( callBack) => ({
    type: TYPES['HOMEBANNER_LIST_ACTION2'],callBack
})