import React, { useEffect } from 'react'

function TermCondition() {

    useEffect(() => {
        // Scroll to the top of the page when the component mounts
        window.scrollTo(0, 0);
    }, []);

  return (
<div className="min-h-screen bg-transparent font-sans">
      {/* Main Content */}
        <section className="max-w-4xl mx-auto bg-white shadow-xl rounded-lg p-6  md:p-12">
          <h2 className="text-2xl font-semibold text-emerald-800 mb-4">Terms and Conditions</h2>
          <p className="text-gray-600 mb-4">
            These Terms and Conditions govern your use of our website and services. By accessing or using our platform, you agree to abide by the following terms:
          </p>

          {/* General Terms */}
          <div className="mb-8">
            <h3 className="text-lg font-semibold text-gray-800 mb-2">1. General Terms</h3>
            <ul className="list-disc list-inside text-gray-600">
              <li>You must be 18 years or older to use our services.</li>
              <li>All personal information provided must be accurate and up to date.</li>
              <li>Unauthorized use of the website may result in legal action.</li>
            </ul>
          </div>

          {/* Account Usage */}
          <div className="mb-8">
            <h3 className="text-lg font-semibold text-gray-800 mb-2">2. Account Usage</h3>
            <ul className="list-disc list-inside text-gray-600">
              <li>You are responsible for maintaining the confidentiality of your account and password.</li>
              <li>We reserve the right to terminate your account if any suspicious activity is detected.</li>
              <li>Do not share your login details with others.</li>
            </ul>
          </div>

          {/* Limitation of Liability */}
          <div>
            <h3 className="text-lg font-semibold text-gray-800 mb-2">3. Limitation of Liability</h3>
            <ul className="list-disc list-inside text-gray-600">
              <li>We are not liable for any damages resulting from misuse of our platform.</li>
              <li>We do not guarantee uninterrupted access to our services.</li>
              <li>Any disputes will be resolved in accordance with applicable laws.</li>
            </ul>
          </div>
        </section>
    </div>  )
}

export default TermCondition